import { render, staticRenderFns } from "./wait-distribute-case.vue?vue&type=template&id=6bfa0fc9&scoped=true"
import script from "./wait-distribute-case.vue?vue&type=script&lang=ts"
export * from "./wait-distribute-case.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bfa0fc9",
  null
  
)

export default component.exports